<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="compLicencasLista"
          sort-by="nome"
          class="elevation-10"
          :footer-props="{
            'items-per-page-options': [20, 40, 60],
          }"
          :search="search"
          :loading="!compLicencasProgress.length"
          loading-text="Carregando..."
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-license</v-icon>
              <v-toolbar-title>Licenças do Sistema</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarLicencaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Licença</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary">
                    <v-chip @click="listaGruAtivos = 'ativos'"> Ativos </v-chip>
                    <v-chip @click="listaGruAtivos = 'inativos'">
                      Inativos
                    </v-chip>
                    <v-chip @click="listaGruAtivos = 'todos'"> Todos </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarLicencaEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  :color="metAtivoColor(item.ativo)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaAtivacao(item)"
                >
                  {{ item.ativo ? "mdi-check-bold" : "mdi-close-thick" }}
                </v-icon>
              </template>
              <span>{{ item.ativo ? "Ativo" : "Inativo" }}</span>
            </v-tooltip>

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
          <template v-slot:[`item.idPerfil`]="{ item }">
            <span>{{ metObterPerfil(item.idPerfil) }} </span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("adminLicencas");

export default {
  components: { ConfirmDialogue },
  // mixins: [],
  name: "AdminLicencasLista",
  data: () => ({
    listaGruAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id" },

      { text: "Licenca", value: "licenca", align: "start" },
      { text: "Perfil", value: "idPerfil", align: "start" },
      { text: "Descrição", value: "descricao", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState([
      "erro",
      "staLicencas",
      "staLicencaSelecionado",
      "staPerfisSelect",
    ]),
    ...mapGetters(["getLicencasAtivos", "getLicencasInativos"]),

    compLicencasLista() {
      return this.listaGruAtivos == "ativos"
        ? this.getLicencasAtivos
        : this.listaGruAtivos == "inativos"
        ? this.getLicencasInativos
        : this.staLicencas;
    },
    compLicencasProgress() {
      return this.staLicencas;
    },
  },

  created() {
    register(this.$store);
    this.actListarLicencas();
    this.actSelectPerfis();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actSelecionarLicenca",
      "actListarLicencas",
      "actAtivarLicenca",
      "actSelectPerfis",
    ]),

    metObterPerfil(id) {
      if (id != null && this.staPerfisSelect.length != 0) {
        const ret = this.staPerfisSelect.filter((c) => c.id == id);
        return ret[0].perfil;
      } else {
        const ret = "";
        return ret;
      }
    },

    metAtivoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    initialize() {},

    metSelecionarLicencaEdicao(licenca) {
      this.actSelecionarLicenca({ licenca });
      this.$router.push({ name: "AdminLicencasForm" });
    },

    metParaAtivacao(licenca) {
      this.actAtivarLicenca({ licenca });
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarLicencas();
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
